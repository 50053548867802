<template>
  <div class="error-404">
    <vue-headful title="Page Not Found | Goodyear SightLine"/>
      <b-container>
        <b-row>
          <b-col sm="12">
            <h1>Sorry about that - we couldn't find the page you're looking for.</h1>
            <p>You may have clicked a link to a page that no longer exists, or it's possible there was a typo in the URL.</p>
            <a class="btn gy-primary backHome" href="/">Visit Homepage</a>
          </b-col>
        </b-row>
      </b-container>
  </div>
</template>

<script>
export default {
  name: 'error-404'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.error-404 {
  background-color: #fff;
  min-height: 500px;
  padding-top: 50px;
  margin-bottom: 25px;
  text-align: center;
}
h1 {
  font-family: 'Barlow', Arial, sans-serif;
  font-weight: bold;
  font-size: 32px;
  color: #000;
}
.btn.gy-primary {
  color: #000;
}
</style>
