<template>
  <div class="global-header">
    <b-navbar class="all-sites-nav">
      <b-container>
        <b-navbar-nav class="ml-auto">
          
          
        </b-navbar-nav>
      </b-container>
    </b-navbar>
    <b-navbar toggleable="lg" is-nav>
      <b-container fluid id="gynav">
        <b-navbar-brand class="gohome" href="/"><img class="logo" src="../assets/images/goodyear-sightline-logo.png" alt="Goodyear SightLine logo"/></b-navbar-brand>

        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

        <b-collapse id="nav-collapse" is-nav>
          <ul class="navbar-nav main">
            <li class="nav-item first"><a class="nav-link" href="/">Home</a></li>
            <li class="nav-item"><a class="nav-link" href="/fleets">Fleets</a></li>
            <li class="nav-item"><a class="nav-link" href="/automakers-suppliers">Automakers & Suppliers</a></li>
          </ul>
          <ul class="navbar-nav mx-auto">
            <li class="nav-item"><a class="btn" href="#contact-us">Contact Us</a></li>
          </ul>
        </b-collapse>

        
      </b-container>
    </b-navbar>

    <b-navbar class="all-sites-nav gy-subnav" v-if="$route.name == 'Home'">
      <b-container>
        <b-navbar-nav align="center">
          <b-nav>
            <li class="nav-item first"><a class="nav-link" href="#about">About</a></li>
            <li class="nav-item"><a class="nav-link" href="#vision">Vision</a></li>
            <li class="nav-item"><a class="nav-link" href="#applications">Applications</a></li>
          </b-nav>
          
        </b-navbar-nav>
      </b-container>
    </b-navbar>
  </div>
</template>

<script>
export default {
  name: 'Header',
  data () {
    return {
      windowWidth: 0
    }
  },
  mounted () {
    this.$nextTick(function () {
      window.addEventListener('resize', this.getWindowWidth)
      // Init
      this.getWindowWidth()
    })
  },
  methods: {
    getWindowWidth () {
      this.windowWidth = document.documentElement.clientWidth

      // small screen
      if (this.windowWidth <= 1200) {
        // console.log('smaller screen detected!')
        document.getElementById('gynav').classList.add('container-fluid')
        document.getElementById('gynav').classList.remove('container')
      } else {
        // console.log('md-lg screen detected!')
        document.getElementById('gynav').classList.add('container')
        document.getElementById('gynav').classList.remove('container-fluid')
      }
    },
    beforeDestroy () {
      window.removeEventListener('resize', this.getWindowWidth)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.all-sites-nav.navbar {
  background-color: #000;
  color: #fff;
  height: 20px;
}
.gy-subnav.all-sites-nav {
  height: 45px;
}
.gy-subnav.all-sites-nav .navbar-nav{
  margin: 0 auto;
}
.wingfoot {
  height: 20px;
}
.navbar {
  color: #fff;
  padding: .2rem .2rem .2rem 1rem;
  z-index: 3;
}
.global-header :focus {
  outline: none;
}
.navbar-brand {
  font-family: "Barlow", Arial, sans-serif;
}
.all-sites-nav.navbar .navbar-nav .nav-item a.nav-link {
  color: #fff;
  padding-left: 10px;
  padding-right: 10px;
  letter-spacing: 0px;
  text-transform: none;
  padding-top: 2px;
  padding-bottom: 2px;
  border-bottom: 0px;
}
.navbar .navbar-nav .nav-item a.nav-link {
  color: #000;
  font-size: 16px;
  font-family: "Barlow", Arial, sans-serif;
  letter-spacing: 1px;
  text-transform: uppercase;
}
.all-sites-nav.navbar .navbar-nav .nav-item:hover, .all-sites-nav.navbar .navbar-nav .nav-item:hover a.nav-link, .all-sites-nav.navbar  .navbar-nav .nav-item a.nav-link:hover {
  text-decoration: none;
  color: #fd0;
}
.all-sites-nav.navbar .navbar-nav .nav-item:hover .b-icon {
  text-decoration: none;
  color: #fff;
}
.navbar-nav .nav-item:hover, .navbar-nav .nav-item:hover a.nav-link, .navbar-nav .nav-item a.nav-link:hover {
  border-top: 0px;
  border-right: 0px;
  /*border-left: 0px;*/
  cursor: pointer;
  color: #000;
  font-weight: bold;
  text-decoration: underline;
}
.logo {
  height: 45px;
  width: auto;
  padding-top: 2px;
  padding-left: 2%;
}
.navbar-nav .nav-item .nav-link:focus {
  color: #000;
  border: none;
}
.navbar .navbar-toggler {
  background-color: rgba(255,255,255, .9);
  border-radius: 0px;
}
.navbar .navbar-toggler .navbar-toggler-icon {
  height: 1em;
  width: 1em;
}
.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.2s ease;
}
ul {
  list-style: none;
  padding-inline-start: 0px;
}

@media (max-width: 445px) {
  .navbar .navbar-nav .nav-item a.nav-link, .btn {
    text-align: center;
  }
  .btn {
    width: 100%;
  }
}
@media (min-width: 445px) and (max-width: 1150px) {
  .btn {
    min-width: auto;
    padding: 5px 10px;
  }
}
@media (max-width: 767px) {
  .logo {
    padding: 5px 2px 5px 2%;
    height: 45px;
  }
  .navbar-toggler-icon {
    height: 1em;
    width: 1em;
  }
}
@media (max-width: 991px) {
  .navbar {
    padding: .2rem .2rem .2rem .2rem;
  }
  .navbar #nav-collapse {
    background-color: #fff;
    padding: .2rem .2rem .2rem 1rem;
  }
  .navbar .navbar-nav .nav-item a.nav-link {
    box-shadow: inset 0 1px 0 rgb(255 255 255 / 10%);
    border-top: 0px;
    border-right: 0px;
    border-left: 0px;
    border-bottom: 1.5px solid;
  }
  .btn {
    background-color: transparent;
    color: #000;
    font-family: "Barlow", Arial, sans-serif;
    padding: 0.5rem 0;
    letter-spacing: 1px;
    font-size: 16px;
  }
  .btn:hover {
    background-color: transparent;
    color: #000;
    text-decoration: underline;
  }
}
@media (min-width: 992px) {
  .navbar {
    padding: 0rem 1rem;
  }
  .navbar-nav.main {
    margin-left: 8%;
  }
  .navbar-nav.main .nav-item.first .nav-link {
    border-left: none;
  }
  .navbar-nav.main .nav-item .nav-link {
    padding: 3px 10px;
    border-left: 1px solid #969696;
  }
  .all-sites-nav.navbar .navbar-nav .nav-item .nav-link {
    margin: 3px 0;
    border-bottom: none;
  }
  .navbar-nav .nav-item .nav-link {
    margin: 12px 0;
    padding: 3px 2px;
    border-bottom: 2px solid transparent;
  }
  .navbar-nav .nav-item a.nav-link {
    -webkit-transition : border .5s linear;
    -moz-transition : border .5s linear;
    -o-transition : border .5s linear;
    transition : border .5s linear;
  }
}

</style>
