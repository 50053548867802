<template>
  <section class="apps-section">
      <b-container>
        <b-row>
          <b-col>
            <span id="applications" class="subheader"></span>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="px-0" sm="12" md="6">
            <div class="card" data-aos="fade-up" data-aos-delay="300" data-aos-once="true">
              <div class="card-body first">
                <h2 class="card-title">Fleets</h2>
                <p class="card-text first">Help minimize downtime and improve tire management with Goodyear SightLine's remote tire health monitoring, customized for your fleet</p>
                <div class="gy-links">
                  <a class="btn gy-primary" href="/fleets">More <b-icon-arrow-right></b-icon-arrow-right> </a>
                </div>
              </div>
            </div>
          </b-col>
          <b-col class="px-0" sm="12" md="6">
            <div class="card" data-aos="fade-up" data-aos-delay="300" data-aos-once="true">
              <div class="card-body second">
                <h2 class="card-title">Automakers & Suppliers</h2>
                <p class="card-text first">Inform your customers and unlock performance with Goodyear SightLine's tire state of life monitoring</p>
                <div class="gy-links">
                  <a class="btn gy-primary" href="/automakers-suppliers">More <b-icon-arrow-right></b-icon-arrow-right> </a>
                </div>
           </div>
            </div>
          </b-col>

        </b-row>
      </b-container>
    </section>
</template>

<script>
export default {
  name: 'Applications'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.apps-section {
  margin-bottom: 25px;
}
.apps-section {
  border-top: 50px solid #000;
}
.card {
  text-align: left;
  border-radius: 0px;
  border-color: #CDCDCD;
}
.gy-links {
  padding-top: 25px;
}
.btn {
  min-width: 150px;
}
::v-deep .modal-content {
  border-radius: 0px;
}
::v-deep .modal-title {
  font-family: "Barlow", Arial, sans-serif;
  font-size: 1.75rem;
  font-weight: bold;
}
::v-deep .modal-header .close {
  font-size: 3.0rem;
  padding: .25rem 1rem 1rem 1rem;
}

@media (min-width: 768px) and (max-width: 992px) {
  .card {
    min-height: 380px;
  }
  .card-body.first .card-text.first {
    min-height: 202px;
  }
  .card-body.second .card-text.first {
    min-height: 164px;
  }
}
@media (max-width: 992px) {
  .apps-section {
    border-top: 100px solid #000;
  }
}
@media (min-width: 992px) and (max-width: 1200px) {
  .card {
    min-height: 325px;
  }
  .card-body.first .card-text.first {
    min-height: 148px;
  }
  .card-body.second .card-text.first {
    min-height: 148px;
  }
}
@media (min-width: 1200px) {
  .card {
    min-height: 285px;
  }
  .card-text.first {
    min-height: 108px;
  }
}
</style>
